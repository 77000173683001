<template>
  <div class="mod-user">
    <div class="searchBox" :style="{ opacity: config.isConfigMode ? 0.5 : 1, pointerEvents: config.isConfigMode ? 'none' : 'auto' }">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="模板名称">
          <el-input v-model="dataForm.templateName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select class="item-choose"
                     v-model="dataForm.status"
                     size="small"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option
              v-for="item in dictTypeMap.status"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本">
          <el-select class="item-choose"
                     v-model="dataForm.versionType"
                     size="small"
                     filterable
                     clearable
                     placeholder="请选择">
            <el-option
              v-for="(item, index) in activeNameArray"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button type="success"
                     v-if="newAuth('template:newPrice:add')"
                     @click="addOrUpdateHandle('add')">新增
          </el-button>
          <el-button size="small" @click="templateConfigurationMode(0)" type="warning">模板配置模式</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox" :style="{ position: 'relative' }">
      <!-- 配置模式 -->
      <el-row v-if="config.isConfigMode" style="justify-self: center;padding: 10px 10px">
        <el-button size="mini" @click="config.isConfigMode = false" type="danger">取消配置模式</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(1)" type="success">保存模板</el-button>
        <el-button v-no-more-click size="mini" @click="templateConfigurationMode(2)" type="warning">修改模板</el-button>
        <el-button v-no-more-click size="mini" @click="$refs.configTableRef.init(config.configTableId)" type="warning">所有模板</el-button>
        <el-popover placement="right" width="520" trigger="click">
          <div style="justify-self: center;">
            <el-button v-no-more-click @click="checkAllNodes(true)" size="mini">全选</el-button>
            <el-button v-no-more-click @click="checkAllNodes(false)" size="mini">全不选</el-button>
            <el-tooltip class="item" effect="dark" content="可拖拽必须先取消固定才允许拖拽排列,如果有固定固定按钮会显示为绿色" placement="top-start">
              <i class="el-icon-view el-icon--right" />
            </el-tooltip>
          </div>
          <br/>
          <el-tree :data="config.configDefaultColumn"
                   style="max-height: 200px;overflow-y: auto;"
                   default-expand-all
                   show-checkbox
                   draggable
                   node-key="prop"
                   ref="configTableListTree"
                   @check-change="handleCheckChange"
                   @node-drop="handleDrop"
                   :allow-drag="allowDrag"
                   :allow-drop="allowDrop">
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <el-row :gutter="20">
                       <el-col :span="16">
                         <div class="grid-content bg-purple">
                           <el-input size="mini" placeholder="列头名" v-model="data.label"></el-input>
                         </div>
                       </el-col>
                       <el-col :span="8">
                         <div class="grid-content bg-purple">
                           <el-link :type="data.fixed && data.fixed === 'left' ? 'success' : 'info'" @click="data.fixed = 'left'" :underline="false" >固定左侧</el-link>
                           <el-link :type="data.fixed && data.fixed === 'right' ? 'success' : 'info'" @click="data.fixed = 'right'" :underline="false" >固定右侧</el-link>
                           <el-link :disabled="!data.fixed" :underline="false" @click="data.fixed = undefined" >取消固定</el-link>
                         </div>
                       </el-col>
                    </el-row>
                  </span>
          </el-tree>
          <el-button style="margin-left: 10px;" size="mini" slot="reference" icon="el-icon-s-tools" circle />
        </el-popover>
        <br/>
        <br/>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-input v-model="config.configModeForm.name" size="mini" placeholder="模板名称" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple-light">
              <el-input v-model="config.configModeForm.remark" size="mini" placeholder="备注" clearable />
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content bg-purple">
              <el-switch
                  v-model="config.configModeForm.defaultOrNot"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="'1'"
                  :inactive-value="'0'">
              </el-switch>
            </div>
          </el-col>
        </el-row>
      </el-row>

      <el-table :data="dataList"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                height="72vh"
                border
                size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;"
                :cell-class-name="getCellClassName">
        <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
        <el-table-column
            v-for="(item, index) in config.configDefaultColumn"
            :key="index"
            align="left"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            :fixed="item.fixed"
            :show-overflow-tooltip="true"
            v-if="item.check"
            :class="item.styleClass"
            :class-name="item.styleClass">
          <template slot-scope="scope">
            <!-- 是否公用 -->
            <template v-if="item.prop == 'publicType'">
              <el-tag v-if="scope.row.publicType === 1" size="small" type="success">是</el-tag>
              <el-tag v-else size="small" type="danger">否</el-tag>
            </template>
            <!-- 版本 -->
            <template v-else-if="item.prop == 'versionType'">
              <el-tag v-if="scope.row.versionType === 'old'" size="small" type="info">旧版</el-tag>
              <el-tag v-if="scope.row.versionType === 'new'" size="small" type="success">新版</el-tag>
            </template>
            <!-- 状态 -->
            <template v-else-if="item.prop == 'status'">
              <el-tag v-if="scope.row.status === 2" size="small" type="danger">禁用</el-tag>
              <el-tag v-else size="small" type="success">启用</el-tag>
            </template>
            <template v-else>
              {{ getFormatter(item.formatter)(scope.row, item, _.get(scope.row, item.prop)) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="newAuth('template:newPrice:find')" type="text" size="small"
                       @click="addOrUpdateHandle('view', scope.row.id)">详情
            </el-button>
            <el-button v-if="newAuth('template:newPrice:update')" type="text" size="small"
                       @click="addOrUpdateHandle('edit', scope.row.id)">修改
            </el-button>
            <el-button v-if="newAuth('template:newPrice:enable')" type="text" size="small"
                       @click="disableOrEnable(scope.row)">{{ scope.row.status === 1 ? '禁用' : '启用' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <!-- 模板配置弹窗 -->
    <configTable ref="configTableRef" />
  </div>
</template>

<script>
import configTable from '@/components/table'
import { newAuth } from '@/utils'
import qs from 'qs'
import * as myJsonClon from "@/utils/myJsonClone";

export default {
  data () {
    return {
      // -----------------动态表格模式start-----------------
      config: {
        configId: null,
        configTableId: 43,
        configDefaultColumn: [
          {label: '模板编号',prop: 'templateCode',fixed: undefined,check: true},
          {label: '模板名称',prop: 'templateName',fixed: undefined,check: true},
          {label: '适用订单类型',prop: 'orderType',width: '60',formatter: 'orderType',check: true,fixed: undefined},
          {label: '是否公用',prop: 'publicType',fixed: undefined,check: true,width: '60'},
          {label: '备注',prop: 'remark',fixed: undefined,check: true},
          {label: '绑定客户',prop: 'customerSimpleName',fixed: undefined,check: true},
          {label: '创建人',prop: 'createName',fixed: undefined,check: true},
          {label: '创建时间',prop: 'createTime',fixed: undefined,check: true,width: '130'},
          {label: '状态',prop: 'status',fixed: undefined,check: true,width: '40'},
          {label: '版本',prop: 'versionType',fixed: 'right',check: true},
        ],
        configDefaultPropColumn: [],
        isConfigMode: false,
        configModeForm: {
          name: '',
          remark: '',
          defaultOrNot: '0'
        },
        configDefaultPropColumnMap: new Map(),
      },
      // -----------------动态表格模式end-----------------

      dataForm: {
        templateName: null,
        orderType: null,
        templateType: 10,
        status: 1,
        customerIdList: [],
        versionType: null
      },
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      activeNameArray: [{ label: '旧版', value: 'old' }, { label: '新版', value: 'new' }]
    }
  },
  components: {configTable},
  activated () {
    this.initConfigTable()

    if (!newAuth('template:newPrice:findAll')) {
      this.dataForm.customerIdList = this.currentUser.customerIdList
    }
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    currentUser: {
      get () {
        return this.$store.state.user.currentUser
      }
    }
  },
  methods: {
    // -----------------动态表格模式start-----------------
    initConfigTable() {
      this.$http({
        url: this.$http.adornUrl('/tabulation/default/' + this.config.configTableId),
        method: 'get',
        params: this.$http.adornParams()
      }).then(async ({ data }) => {
        if (data) {
          this.config.configModeForm.name = data.name
          this.config.configModeForm.remark = data.remark
          this.config.configModeForm.defaultOrNot = data.defaultOrNot
          this.config.configId = data.id
          if (data.tmsTabulationConfigValueList && data.tmsTabulationConfigValueList.length > 0) {
            this.assignPropertiesLarge(this.config.configDefaultColumn, data.tmsTabulationConfigValueList,'prop',['label','fixed','width'])
            this.config.configDefaultColumn = this.reorderArray(this.config.configDefaultColumn, data.tmsTabulationConfigValueList, 'prop');
          }
        }
        this.config.configDefaultColumn.forEach(x => {
          this.config.configDefaultPropColumn.push({prop: x.prop})
          this.config.configDefaultPropColumnMap.set(x.prop,x)
        })
      })

    },
    assignPropertiesLarge (arr1, arr2, compareProp, assignProps) {
      const map = new Map();
      arr2.forEach(item => {
        map.set(item[compareProp], item);
      });
      arr1.forEach(item => {
        const match = map.get(item[compareProp]);
        if (match) {
          assignProps.forEach(prop => {
            item[prop] = match[prop];
          });
          item.check = true
        } else {
          item.check = false
        }
      });
      return arr1;
    },
    reorderArray(arr1, arr2, key) {
      if (arr2.length === 0) {
        return arr1;
      }

      // 存储第二个数组中元素的特定属性值
      const targetValues = arr2.map(item => item[key]);
      // 存储匹配的元素
      const matched = [];
      // 存储未匹配的元素
      const unmatched = [];

      // 遍历第一个数组，将匹配的元素和未匹配的元素分别存储
      for (const item of arr1) {
        if (targetValues.includes(item[key])) {
          matched.push(item);
        } else {
          unmatched.push(item);
        }
      }

      // 对匹配的元素进行排序，使其顺序与第二个数组一致
      const sortedMatched = targetValues.map(value => {
        return matched.find(item => item[key] == value);
      }).filter(item => item!== undefined);
      // 合并匹配的元素和未匹配的元素
      return [...sortedMatched, ...unmatched];
    },
    templateConfigurationMode(type) {
      // 开启配置模式
      if (type === 0) {
        this.config.isConfigMode = true
        this.$nextTick(() => {
          const tree = this.$refs.configTableListTree;
          this.config.configDefaultColumn.forEach((node) => {
            if (node.check) {
              tree.setChecked(node.prop, true);
            }
          });
        })
      }
      // 保存/修改配置模式
      else if (type === 1 || type === 2) {
        // 检测
        if (!this.config.configModeForm.name) {
          this.$message.error('请输入配置模板名称!');
          return
        }
        if (type === 2) {

          if (!this.config.configId) {
            this.$message.error('未检测出有配置相关模板并启用,请新增!');
            return
          }
        }
        this.$nextTick(() => {
          let submitTable = myJsonClon.getClone(this.config.configModeForm)
          submitTable.tableId = this.config.configTableId
          submitTable.id = type === 2 ? this.config.configId : undefined
          const tableRef = this.$refs.tableRef;
          let tmsTabulationConfigValueList = []
          tableRef.columns.forEach((x,index) => {
            tmsTabulationConfigValueList.push({label: x.label,prop: x.property,fixed: x.fixed,width: x.width,sort: index})
          })
          submitTable.tmsTabulationConfigValueList = tmsTabulationConfigValueList;
          this.$http({
            url: this.$http.adornUrl(type === 1 ? '/tabulation/add' : '/tabulation/update'),
            method: type === 1 ? 'post' : 'put',
            data: submitTable
          }).then(async ({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
            window.location.reload()
          })

        })
      }
    },
    getFormatter(formatterName) {
      if (formatterName) {
        // 首先尝试从组件的 methods 中获取方法
        let method = this[formatterName];
        if (typeof method === 'function') {
          return method;
        }
        // 若组件的 methods 中没有，再尝试从 Vue.prototype 中获取方法
        method = Vue.prototype[formatterName];
        if (typeof method === 'function') {
          return method;
        }
      }
      // 若都没找到，返回一个直接返回原始值的函数
      return (row, column, cellValue) => cellValue;
    },
    checkAllNodes (isChecked) {
      this.$nextTick(() => {
        if (isChecked) {
          this.$refs.configTableListTree.setCheckedNodes(this.config.configDefaultPropColumn)
        } else {
          this.$refs.configTableListTree.setCheckedKeys([])
          this.$forceUpdate()
        }
      })
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      const tree = this.$refs.configTableListTree;
      tree.setChecked(draggingNode.data.prop, draggingNode.data.check);
    },
    handleCheckChange(data, checked, indeterminate) {
      const targetElement = this.config.configDefaultColumn.find(item => item.prop === data.prop);
      targetElement.check = checked
    },
    allowDrag(draggingNode) {
      return draggingNode.data.fixed ? false : true
    },
    allowDrop(draggingNode, dropNode, type) {
      return type === 'inner' ? false : true
    },
    getCellClassName({ row, column, rowIndex, columnIndex }) {
      let newVar = this.config.configDefaultPropColumnMap.get(column.property);
      if (newVar && newVar.styleClass) {
        return newVar.styleClass;
      }
      return '';
    },
    // -----------------动态表格模式end-----------------

    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    /**
     * 点击表格变色end
     */
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let param = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'status': this.dataForm.status,
        'templateType': this.dataForm.templateType,
        'orderType': this.dataForm.orderType,
        'templateName': this.dataForm.templateName,
        'customerIdList': this.dataForm.customerIdList,
        'versionType': this.dataForm.versionType
      }
      const params = qs.stringify(param, { arrayFormat: 'repeat' })
      this.$http({
        url: this.$http.adornUrl('/priceNewTemplate/findList?' + params),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.$router.push({
        name: 'priceTemplatePage',
        params: {
          id: id,
          todo: todo
        }
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.templateName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/priceTemplate/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
<style lang="less" scoped>
:deep(.my-table-cell) {
  background-color: antiquewhite!important;
}
:deep(.my-table-cell-v2) {
  background-color: beige!important;
}
:deep(.my-table-cell-v3) {
  background-color: aliceblue!important;
}
</style>
